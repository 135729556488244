import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FaRegEdit, FaEye, FaSearch } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { MdAutoGraph } from "react-icons/md";
import company from "../../assets/img/icons/building.png";
import { MdBusinessCenter } from "react-icons/md";
import { SiSmartthings } from "react-icons/si";
import { MdAttachMoney, MdShowChart } from "react-icons/md";
import { BsGraphUpArrow, BsGraphDownArrow } from "react-icons/bs";
import { GoGraph } from "react-icons/go";
import { CiMoneyBill } from "react-icons/ci";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { TfiReload } from "react-icons/tfi";
export default function Straties() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [baseurl, setbaseurl] = useState();
  const [Allcompany, setCompany] = useState();
  const [Activecompany, setActivecompany] = useState();
  const [Inactivecompany, setInactivecompany] = useState();

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    companyName: "",
    strategyName: "",
    symbolName: "",
    fromDate: null,
    toDate: null,
  });
  const [formdata, setformdata] = useState({
    fromDate: null,
    toDate: null,
  });
  const broker_id = localStorage.getItem("broker_id");
  const token = localStorage.getItem("bear_token");

  const [totalSwanHedge, setTotalSwanHedge] = useState(0);
  //   console.warn(totalSwanHedge)
  const [totalActiveSwanHedge, setTotalActiveSwanHedge] = useState(0);
  const [totalActiveSwanNaked, setTotalSwanNaked] = useState(0);
  const [totalInActiveSwanHedge, setTotalInActiveSwanHedge] = useState(0);
  const [NetProofitLossSwanHedge, setNetProofitLossSwanHedge] = useState(0);
  const [Strategyy, SetStrategyy] = useState();
  console.warn(Strategyy);
  useEffect(() => {
    fetchData();
  }, [filters.toDate]);
  const fetchData = async () => {
    try {
      setLoading(true);

      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const params = {
        smalladmins_id: broker_id,
        status: "1",
        date_from: filters.fromDate
          ? new Date(filters.fromDate.getTime() + 86400000)
            .toISOString()
            .split("T")[0]
          : "",
        date_to: filters.toDate
          ? new Date(filters.toDate.getTime() + 86400000)
            .toISOString()
            .split("T")[0]
          : "",
      };

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small_admins_add_company_nakedHedgePL_list`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: params,
      };

      const response = await axios(config);
      console.warn(response);

      setData(response.data.data);
      const strategyTypes = response.data.data.map(
        (item) => item.strategy_type
      );

      SetStrategyy(strategyTypes);
      // SetStrategyy(response.data.data.strategy_type);
      setTotalSwanNaked(response.data.nakedtradeprizeperorder);
      setTotalSwanHedge(response.data.hedgetradeprizeperorder);
      setTotalInActiveSwanHedge(response.data.grossProofitLoss);
      setNetProofitLossSwanHedge(response.data.NetProofitLoss);
      setTotalActiveSwanHedge(response.data.total_no_of_TradesCount);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const filteredData = data.filter((item) => {
    const companyNameMatch =
      filters.companyName === "" ||
      item.company_name
        .toLowerCase()
        .includes(filters.companyName.toLowerCase());
  
    const strategyNameMatch =
      filters.strategyName === "" ||
      item.strateigs_name
        .toLowerCase()
        .includes(filters.strategyName.toLowerCase());
  
    const symbolMatch =
      !filters.symbolName || // Check for null, undefined, or empty string
      (item.symbol_name && item.symbol_name.toLowerCase().includes(filters.symbolName?.toLowerCase() || ""));
  
    return companyNameMatch && strategyNameMatch && symbolMatch;
  });
  
  

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const Delete = async (id) => {
    console.warn(id);
    const token = localStorage.getItem("bear_token");
    const broker_id = localStorage.getItem("broker_id");

    if (!id) {
      console.error("ID is required");
      return;
    }

    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        color:"white",
        background: 'transparent',
      });

      if (result.isConfirmed) {
        setLoading(true);

        const formData = new FormData();
        formData.append("smalladmins_id", broker_id);
        formData.append("id", id);
        formData.append("status", "1");

        const config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}small_admins_delete_company_nakedHedgePL`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        };

        const response = await axios(config);
        const data = response.data.status;

        if (data === 1) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            color:"white",
            background: 'transparent',
          });

          fetchData();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data.message,
              color:"white",
          background: 'transparent',
          });
        }
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleStatusChange = async (business_id, currentStatus) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change status!",
        color:"white",
        background: 'transparent',
      });

      if (result.isConfirmed) {
        setLoading(true);

        const formData = new FormData();
        formData.append("smalladmins_id", broker_id);
        formData.append("id", business_id);
        formData.append("status", currentStatus === 1 ? 0 : 1);

        const config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}small_admins_update_company_nakedHedgePL_status`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        };

        const response = await axios(config);
        const data = response.data.status;

        if (data === 1) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Status updated successfully!",
            // timer: 1500,
          });

          fetchData(); // Refresh data after status change
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data.message,
              color:"white",
          background: 'transparent',
          });
        }

        setLoading(false);
      }
    } catch (error) {
      console.error("Error changing status:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error changing the status.",
          color:"white",
          background: 'transparent',
      });
      setLoading(false);
    }
  };

  const handleAddbusiness = (id, name) => {
    localStorage.setItem("company_id", id);
    localStorage.setItem("company_Name", name);
    navigate("/Addbusiness");
  };

  const HandleAddadminscustomer = (id, name) => {
    localStorage.setItem("company_id", id);
    localStorage.setItem("company_Name", name);
    navigate("/Add-admins-customer");
  };

  const handleClickEditCompanyId = (id) => {
    localStorage.setItem("ViesNaked", id);
    navigate("/EditNakedBuySell");
  };

  const handleClickCompanyId = (id) => {
    localStorage.setItem("ViesNaked", id);
    navigate("/ViesNaked");
  };
  const handleClickCompanyintraday = (id,date_from,date_to,company_id,strateigs_id,company_name,strateigs_name,strategy_type) => {
    localStorage.setItem("ViesNakedpotionn", id);
    localStorage.setItem("date_from", date_from);
    localStorage.setItem("date_to", date_to);
    localStorage.setItem("company_id123", company_id);
    localStorage.setItem("company_name123", company_name);
    localStorage.setItem("strateigs_name123", strateigs_name);
    localStorage.setItem("strateigs_type123", strategy_type);
    localStorage.setItem("strateigs_id123", strateigs_id);
    navigate("/ViesNakedpotionn");

  };
  const handleRefresh = () => {
    setFilters({
      companyName: "",
      strategyName: "",
      symbolName: "",
      fromDate: null,
      toDate: null,
    });
    fetchData();
  };

  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        {/*/.bg-holder*/}
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Company Profit And Loss Analytics</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-3 mb-3">
        <div className="col-md-3 col-xxl-3 col-25">
          <Link to="#" className={`linkWithoutUnderline active`}>
            <div className="card h-md-100 ecommerce-card-min-width">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2 fs-9 d-flex align-items-center">
                  Naked Trade Price Per Order
                </h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row align-items-center">
                  <div className="col pe-0">
                    <CiMoneyBill className="fs-4 text-primary" />
                  </div>
                  <div className="col ps-0">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-primary">
                      {totalActiveSwanNaked}
                    </p>
                  </div>
                  <div className="col-auto ps-0">
                    <div className="echart-bar-weekly-sales h-100" />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-3 col-xxl-3 col-25">
          <Link to="#" className="linkWithoutUnderline">
            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2 fs-9"> Hedge Trade Price Per Order</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col pe-0">
                    {totalSwanHedge >= 0 ? (
                      <CiMoneyBill className="fs-5 text-success" />
                    ) : (
                      <CiMoneyBill className="fs-5 text-danger" />
                    )}
                  </div>
                  <div className="col ps-0">
                    <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1">
                      <p
                        className={`font-sans-serif lh-1 mb-1 fs-5 ${totalSwanHedge >= 0 ? "text-success" : "text-danger"
                          }`}
                      >
                        {totalSwanHedge}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-3 col-xxl-3 col-25">
          <Link to="#" className="linkWithoutUnderline">
            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2 fs-9"> Total no of Trades</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col pe-0">
                    <GoGraph className="fs-5 text-success" />
                  </div>
                  <div className="col ps-0">
                    <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                      <p className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                        {totalActiveSwanHedge}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-3 col-xxl-3 col-25">
          <Link to="#" className="linkWithoutUnderline">
            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2 fs-9"> Gross Profit & Loss</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col pe-0">
                    {/* <BsGraphUpArrow className="fs-4 text-info" /> */}
                    {totalInActiveSwanHedge >= 0 ? (
                      <BsGraphUpArrow className={`fs-5  ${totalInActiveSwanHedge > 0 ? 'text-success' : totalInActiveSwanHedge < 0 ? 'text-danger' : 'text-dark'}`} />
                    ) : (
                      <BsGraphDownArrow className={`fs-5 ${totalInActiveSwanHedge > 0 ? 'text-success' : totalInActiveSwanHedge < 0 ? 'text-danger' : 'text-dark'}`} />
                    )}
                  </div>
                  <div className="col ps-0">
                    <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1">
                      <p
                        className={`font-sans-serif lh-1 mb-1 fs-5  ${totalInActiveSwanHedge > 0 ? 'text-success' : totalInActiveSwanHedge < 0 ? 'text-danger' : 'text-dark'}`}
                      >
                        {totalInActiveSwanHedge}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-3 col-xxl-3 col-25">
          <Link to="#" className="linkWithoutUnderline">
            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2 fs-9"> Net Proofit And Loss</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col pe-0">
                    {NetProofitLossSwanHedge >= 0 ? (
                      <BsGraphUpArrow className="fs-5 text-success" />
                    ) : (
                      <BsGraphDownArrow className="fs-5 text-danger" />
                    )}
                  </div>
                  <div className="col ps-0">
                    <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1">
                      <p
                        className={`font-sans-serif lh-1 mb-1 fs-5 ${NetProofitLossSwanHedge >= 0
                            ? "text-success"
                            : "text-danger"
                          }`}
                      >
                        {NetProofitLossSwanHedge}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="card mb-3">
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Company Profit And Loss List</h3>
            </div>
            <div className="col-md-4 d-flex justify-content-end">
              <Link to="/AddNakedadd" className="btn btn-sm btn-success">
                <IoMdAdd className="me-1 fs--2" /> Add Company P&L
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="card " id="ticketsTable ">
        <div className="row mt-3 mx-2 g-3">
          <SearchSelect
            label="Company Name"
            value={filters.companyName}
            onChange={(value) => setFilters({ ...filters, companyName: value })}
            options={data.map((item) => ({
              value: item.company_name,
              label: item.company_name,
            }))}
          />
          <SearchSelect
            label="Strategy Name"
            value={filters.strategyName}
            onChange={(value) =>
              setFilters({ ...filters, strategyName: value })
            }
            options={data.map((item) => ({
              value: item.strateigs_name,
              label: item.strateigs_name,
            }))}
          />
          <SearchSelect
            label="Symbol Name"
            value={filters.symbolName}
            onChange={(value) => setFilters({ ...filters, symbolName: value })}
            options={data.map((item) => ({
              value: item.symbol_name,
              label: item.symbol_name,
            }))}
          />

          <div className="col-lg-2  form_details ">
            <div className="form-group local-forms">
              <label className="form-label">From Date</label>
              <DatePicker
                selected={filters.fromDate}
                onChange={(date) => setFilters({ ...filters, fromDate: date })}
                className="form-control"
                id="fromDate"
                dateFormat="dd-MM-yyyy"
                placeholderText="DD-MM-YYYY"
              />
            </div>
          </div>
          <div className="col-lg-2  form_details ">
            <div className="form-group local-forms">
              <label className="form-label">To Date</label>
              <DatePicker
                selected={filters.toDate}
                onChange={(date) => setFilters({ ...filters, toDate: date })}
                className="form-control"
                id="toDate"
                dateFormat="dd-MM-yyyy"
                placeholderText="DD-MM-YYYY"
              />
            </div>
          </div>

          <div className="col-lg-1  form_details ">
            <button className="btn btn-primary ml-auto" onClick={handleRefresh}>
              <TfiReload className="fs-6 text-light" />
            </button>
          </div>
          <div className="col-lg-1  form_details mt-3"></div>
        </div>
        <div className="card-body">
          <div className="table-responsive scrollbar">
            <table className="table mb-0 table-view-tickets">
              <thead className="bg-body-tertiary">
                <tr className="thead-dark">
                  <th className="">Sr.</th>
                  <th
                    className="text-800 sort align-middle"
                    data-sort="company_name"
                  >
                    Company
                  </th>
                  <th
                    className="text-800 sort align-middle"
                    data-sort="strateigs_name"
                  >
                    Strategy
                  </th>
                  <th
                    className="text-800 sort align-middle"
                    data-sort="strateigs_name"
                  >
                    Type
                  </th>
                  <th
                    className="text-800 sort align-middle"
                    data-sort="symbol_name"
                  >
                    Symbol
                  </th>
                  <th
                    className="text-800 sort align-middle"
                    data-sort="buy_price"
                  >
                    Start Date
                  </th>
                  <th
                    className="text-800 sort align-middle"
                    data-sort="sell_price"
                  >
                    End Date
                  </th>
                  <th className="text-800 sort align-middle" data-sort="status">
                    Status
                  </th>
                  <th
                    className="text-800 sort align-middle"
                    data-sort="addbusiness"
                  >
                    Action
                  </th>
                </tr>
              </thead>

              <tbody className="list">
                {loading && (
                  <tr>
                    <td colSpan="7" className="text-center py-4">
                      <div id="preloader">
                        <div id="loading-center">
                          <div className="loader">
                            <div className="waviy">
                              <img
                                src="https://swaninvestment.in/frontant/assets/img/loader.png"
                                alt="Loader image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {paginatedData.length > 0 ? (
                  paginatedData.map((item, index) => (
                    <tr key={index} className="">
                      <td className="align-middle client white-space-nowrap">
                        <div className="d-flex align-items-center gap-2 position-relative">
                          <div>
                            <span>{index + 1}</span>
                          </div>
                        </div>
                      </td>
                      <td className="align-middle priority pe-4">
                        <div className="d-flex align-items-center gap-2">
                          <h6 className="mb-0 text-700 text-transform-capitalize">
                            {item?.company_name ?? "No Name"}
                          </h6>
                        </div>
                      </td>
                      <td className="align-middle priority pe-4">
                        <div className="d-flex align-items-center gap-2">
                          <h6 className="mb-0 text-700 text-transform-capitalize">
                            {item?.strateigs_name ?? "No Name"}
                          </h6>
                        </div>
                      </td>
                      <td className="align-middle priority pe-4">
                        <div className="d-flex align-items-center gap-2">
                          <h6 className="mb-0 text-700 text-transform-capitalize">
                            {item?.strategy_type ?? "NA"}
                          </h6>
                        </div>
                      </td>
                      <td className="align-middle priority pe-4">
                        <div className="d-flex align-items-center gap-2">
                          <h6 className="mb-0 text-700 text-transform-capitalize">
                            {item?.symbol_name ?? "NA"}
                          </h6>
                        </div>
                      </td>
                      <td className="align-middle priority pe-4">
                        <div className="d-flex align-items-center gap-2">
                          <h6 className="mb-0 text-700 text-transform-capitalize">
                            {item?.date_from ?? "No Price"}
                          </h6>
                        </div>
                      </td>
                      <td className="align-middle priority pe-4">
                        <div className="d-flex align-items-center gap-2">
                          <h6 className="mb-0 text-700 text-transform-capitalize">
                            {item?.date_to ?? "No Price"}
                          </h6>
                        </div>
                      </td>

                      <td>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input toggle_btn"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheckDefault-${item.id}`}
                            checked={item.status === 1}
                            onChange={() =>
                              handleStatusChange(item.id, item.status)
                            }
                            disabled={loading}
                          />
                        </div>
                      </td>
                      <td className="align-middle priority pe-4">
                        <div className="d-flex gap-1 action_button">
                          {/* <OverlayTrigger
                          key="Edit"
                          placement="top"
                          overlay={<Tooltip id={`Edit`}>Edit</Tooltip>}
                        >
                          <button
                            onClick={() => handleClickEditCompanyId(item.id)}
                            style={{ "--i": "#2c7be5" }}
                          >
                            {" "}
                            <FaRegEdit className="color_link" />
                          </button>
                        </OverlayTrigger> */}
                          <OverlayTrigger
                            key={`View-${item.strategy_type}`}
                            placement="top"
                            overlay={
                              <Tooltip id={`View-${item.strategy_type}`}>
                                {item.strategy_type === "positional"
                                  ? "View positional"
                                  : "View intraday"}
                              </Tooltip>
                            }
                          >
                            {/* <button
                              onClick={() => handleClickCompanyId(item.id)}
                              style={{
                                backgroundColor:
                                  item.strategy_type === "positional"
                                    ? "#27bcfd"
                                    : "#ff5733",
                                color: "white",
                                border: "none",
                                padding: "10px 20px",
                                // borderRadius: "5px",
                              }}
                            >
                              <FaEye className="color_link" />
                            </button> */}


{item.strategy_type === "positional" ? (
  <button
    onClick={() => handleClickCompanyId(item.id)}
    style={{
      backgroundColor: "#27bcfd", 
      color: "white",
      border: "none",
    }}
  >
    <FaEye className="d-inline" /> 
  </button>
) : (
  <button
    onClick={() => handleClickCompanyintraday(item.id,item.date_from,item.date_to,item.company_id,item.strateigs_id,item.company_name,item.strateigs_name,item.strategy_type)}
    style={{
      backgroundColor: "#ff5733", 
      color: "white",
      border: "none",
    }}
  >
    <FaEye className="d-inline" /> 
  </button>
)}


                          </OverlayTrigger>

                          <OverlayTrigger
                            key="Delete"
                            placement="top"
                            overlay={<Tooltip id={`Delete`}>Delete</Tooltip>}
                          >
                            <button
                              style={{ "--i": "red" }}
                              onClick={() => Delete(item.id)}
                            >
                              {" "}
                              <MdDelete className="color_link" />
                            </button>
                          </OverlayTrigger>


                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center py-4">
                      No data found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {totalPages > 1 && (
            <div className="pagination mt-3 mb-3 mx-3">
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <IoIosArrowBack />
              </button>
              {[...Array(totalPages).keys()].map((pageNumber) => (
                <button
                  key={pageNumber + 1}
                  className={`btn btn-sm mx-1 ${currentPage === pageNumber + 1
                      ? "btn-light text-primary"
                      : "btn-light"
                    }`}
                  onClick={() => handlePageChange(pageNumber + 1)}
                >
                  {pageNumber + 1}
                </button>
              ))}
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <IoIosArrowForward />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
const SearchSelect = ({ label, value, onChange, options }) => (
  <div className="col-lg-2  form_details ">
    <div className="form-group local-forms">
      <label className="form-label">{label}</label>
      <select
        className="form-select mx-1"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value="">All</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  </div>
);
