import React, { useState,useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";

export default function AddEmployeeSalary() {
  const navigate = useNavigate();
  const broker_id = localStorage.getItem("broker_id");
  const employee_idTransaction = localStorage.getItem("employee_id");

  const [basicSalary, setBasicSalary] = useState("");
  const [salarySlipDate, setSalarySlipDate] = useState("");
  const [hra, setHra] = useState("");
  const [otherAllowances, setOtherAllowances] = useState("");
  const [arrears, setArrears] = useState("");
  const [convenienceAllowance, setConvenienceAllowance] = useState("");
  const [expectedBonus, setExpectedBonus] = useState("");
  const [medical, setMedical] = useState("");
  const [phoneAndOtherAllowance, setPhoneAndOtherAllowance] = useState("");
  const [specialPayTraining, setSpecialPayTraining] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const MAX_LIMIT = 1000000000; 

  const isNumeric = (value) => {
    return !isNaN(value) && value.trim() !== "" && parseFloat(value) <= MAX_LIMIT;
  };

  const handleChange = (setter) => (e) => {
    const value = e.target.value;
    if (isNumeric(value) || value === "") {
      setter(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  
    const token = localStorage.getItem("bear_token");
    const dataUpdate = new FormData();
    
    dataUpdate.append("smalladmins_id", broker_id);
    dataUpdate.append("emp_id", employee_idTransaction);
    dataUpdate.append("basic_salary", basicSalary);
    dataUpdate.append("salary_slip_date", salarySlipDate);
    dataUpdate.append("hra", hra);
    dataUpdate.append("other_allowances", otherAllowances);
    dataUpdate.append("arrears", arrears);
    dataUpdate.append("convence_allowance", convenienceAllowance);
    dataUpdate.append("expected_bonus", expectedBonus);
    dataUpdate.append("medical", medical);
    dataUpdate.append("phone_andother_allowance", phoneAndOtherAllowance);
    dataUpdate.append("special_pay_training", specialPayTraining);
  
    try {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-emp-add-salary-payslip`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: dataUpdate,
      };
  
      const response = await axios(config);
  
      
      if (response.data.message === "Salary Added Successfully") {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
          timer: 2000,
        });
  
        setTimeout(() => {
          navigate("/Employee-Salary-Slip-details");
        }, 2000);
      }
      
      
      else if (response.data.message === "This month salary already added") {
        Swal.fire({
          icon: "error",
          title: "Sorry!",
          text: "This month's salary has already been added!",
        });
      }
  
      
      else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred while submitting the form.",
        });
      }
    } catch (error) {
     
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while submitting the form.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setSalarySlipDate(today);
  }, []);
  return (
    <>
      <div className="card mb-3">
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Add Employee Salary</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/Employee-Salary-Slip-details">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <form className="row g-3" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="input-style-1">
                  <label>Basic Salary <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    name="basic_salary"
                    value={basicSalary}
                    onChange={handleChange(setBasicSalary)}
                    placeholder="Enter Basic Salary"
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-style-1">
                  <label>HRA <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    name="hra"
                    value={hra}
                    onChange={handleChange(setHra)}
                    placeholder="Enter HRA"
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-style-1">
                  <label>Other Allowances <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    name="other_allowances"
                    value={otherAllowances}
                    onChange={handleChange(setOtherAllowances)}
                    placeholder="Enter Other Allowances"
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-style-1">
                  <label>Arrears <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    name="arrears"
                    value={arrears}
                    onChange={handleChange(setArrears)}
                    placeholder="Enter Arrears"
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-style-1">
                  <label>Convenience Allowance <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    name="convenience_allowance"
                    value={convenienceAllowance}
                    onChange={handleChange(setConvenienceAllowance)}
                    placeholder="Enter Convenience Allowance"
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-style-1">
                  <label>Expected Bonus <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    name="expected_bonus"
                    value={expectedBonus}
                    onChange={handleChange(setExpectedBonus)}
                    placeholder="Enter Expected Bonus"
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-style-1">
                  <label>Medical <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    name="medical"
                    value={medical}
                    onChange={handleChange(setMedical)}
                    placeholder="Enter Medical Allowance"
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-style-1">
                  <label>Phone and Other Allowance <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    name="phone_and_other_allowance"
                    value={phoneAndOtherAllowance}
                    onChange={handleChange(setPhoneAndOtherAllowance)}
                    placeholder="Enter Phone and Other Allowance"
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-style-1">
                  <label>Special Pay for Training <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    name="special_pay_training"
                    value={specialPayTraining}
                    onChange={handleChange(setSpecialPayTraining)}
                    placeholder="Enter Special Pay for Training"
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
  <div className="input-style-1">
    <label>Salary Slip Date <span className="text-danger">*</span></label>
    <input
      type="date"
      name="salary_slip_date"
      value={salarySlipDate.split("T")[0]} 
      onChange={(e) => setSalarySlipDate(e.target.value)}
      required
    />
  </div>
</div>

            </div>

            <div className="col-12 d-flex justify-content-end">
              <button
                className="btn btn-primary"
                type="submit"
                disabled={isSubmitting}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
