import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaUserAlt, FaIdBadge, FaEnvelope, FaMoneyCheckAlt, FaCalendarAlt, FaBuilding, FaHandHoldingUsd, FaHospitalAlt, FaPhoneAlt, FaUniversity, FaPercent } from 'react-icons/fa';
import { BsCurrencyRupee } from "react-icons/bs";
export default function SalarySlipView() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const TransactionView_id = localStorage.getItem("SalarySlipView_id");
      const emp_id = localStorage.getItem("employee_id");

      const formData = new FormData();
      formData.append("smalladmins_id", localStorage.getItem("broker_id"));
      formData.append("id", TransactionView_id);
      formData.append("emp_id", emp_id);

      const response = await axios.post(`${process.env.REACT_APP_API_URL}small_admins_employee_tranx_payslip_view`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      setData(response.data.data[0]);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="container mt-4">
   <div className="card mb-3">
  <div
    className="bg-holder d-none d-lg-block bg-card"
    style={{
      backgroundImage:
        'url("../../assets/img/icons/spot-illustrations/corner-4.png")'
    }}
  />
  <div className="card-body position-relative">
    <div className="row">
      <div className="col-lg-8">
        <h2 className="mb-0">Employee Salary Slip Details</h2>
      </div>
      <div className="col-lg-4 text-end">
        {/* <a className="" href="/Employeeedit">
          <button className="btn btn-primary">Update</button>
        </a> */}
        <a  href="/Employee-Salary-Slip-details">
          <button className="btn btn-success">Back</button>
        </a>
      </div>
    </div>
  </div>
</div>


      <div className="card">
    
        <div className="card-body">
          {/* Employee Details Section */}
          <h4>Employee Details</h4>
          <table className="table">
            <tbody>
              <tr>
                <th>Employee Name</th>
                <td style={{ color: 'green', fontWeight: 'bold' }}><FaUserAlt className="me-2" />{data.emp_name}</td>
              </tr>
              <tr>
                <th>Employee ID</th>
                <td style={{ color: 'blue', fontWeight: 'bold' }}><FaIdBadge className="me-2" />{data.emp_id}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td style={{ color: 'blue', fontWeight: 'bold' }}><FaEnvelope className="me-2" />{data.email}</td>
              </tr>
              <tr>
                <th>Salary Slip Date</th>
                <td style={{ color: 'red', fontWeight: 'bold' }}><FaCalendarAlt className="me-2" />{data.salary_slip_date}</td>
              </tr>
              <tr>
                <th>UAN Number</th>
                <td style={{ color: 'brown', fontWeight: 'bold' }}><FaIdBadge className="me-2" />{data.uan_number}</td>
              </tr>
            </tbody>
          </table>
          </div>
       
      </div>
      <div className="card mt-2">
      <div className="card-body">
                    {/* Salary Details Section */}
                    <h4>Salary Details</h4>
                    <table className="table">
                      <tbody>
                      
                      
                        <tr>
                          <th>Basic Salary</th>
                          <td style={{fontWeight:"bold",color:"black" }}><BsCurrencyRupee  className="me-2" />{data.basic_salary || 0}/-</td>
                        </tr>
                        <tr>
                          <th>HRA</th>
                          <td style={{fontWeight:"bold",color:"black" }}><BsCurrencyRupee  className="me-2" />{data.hra || 0}/-</td>
                        </tr>
                        <tr>
                          <th>Other Allowances</th>
                          <td style={{fontWeight:"bold",color:"black" }}><BsCurrencyRupee  className="me-2" />{data.other_allowances || 0}/-</td>
                        </tr>
                        <tr>
                          <th>Arrears</th>
                          <td style={{fontWeight:"bold",color:"black" }}><BsCurrencyRupee  className="me-2" />{data.arrears || 0}/-</td>
                        </tr>
                        <tr>
                          <th>Convence Allowance</th>
                          <td style={{fontWeight:"bold",color:"black" }}><BsCurrencyRupee  className="me-2" />{data.convence_allowance || 0}/-</td>
                        </tr>
                        <tr>
                          <th>Expected Bonus</th>
                          <td style={{fontWeight:"bold",color:"black" }}><BsCurrencyRupee  className="me-2" />{data.expected_bonus || 0}/-</td>
                        </tr>
                        <tr>
                          <th>Medical</th>
                          <td style={{fontWeight:"bold",color:"black" }}><BsCurrencyRupee  className="me-2" />{data.medical || 0}/-</td>
                        </tr>
                        <tr>
                          <th>Phone & Other Allowance</th>
                          <td   style={{fontWeight:"bold",color:"black" }}><BsCurrencyRupee  className="me-2" />{data.phone_andother_allowance || 0}/-</td>
                        </tr>
                        <tr>
                          <th>Special Pay (Training)</th>
                          <td  style={{fontWeight:"bold",color:"black" }}><BsCurrencyRupee  className="me-2" />{data.special_pay_training || 0}/-</td>
                        </tr>
                        <tr>
                          <th style={{fontWeight:"bold",color:"green" }}>Net Payable Salary</th>
                          <td  style={{fontWeight:"bold",color:"green" }}><BsCurrencyRupee  className="me-2" />{data.net_payable_salary || 0}/-</td>
                        </tr>
                      </tbody>
                    </table>
          </div>
         
      </div>
      <div className="card mt-2">
      <div className="card-body">
                    {/* Bank Details Section */}
                    <h4>Bank Details</h4>
                    <table className="table">
                      <tbody>
                        <tr>
                          <th>Bank Details</th>
                          <td  style={{fontWeight:"bold",color:"black" }}><FaUniversity className="me-2" />{data.bank_details}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
      </div>
    </div>
  );
}
