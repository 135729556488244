import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaFilePdf, FaRegEdit, FaEye, FaSearch } from "react-icons/fa";
import { FaCloudDownloadAlt } from "react-icons/fa";
import {
  MdDelete,
  MdOutlinePendingActions,
  MdOutlineSmsFailed,
  MdArrowOutward
} from "react-icons/md";
import { BsCurrencyRupee } from "react-icons/bs";
import { parse, isWithinInterval } from "date-fns";
import { GiReceiveMoney } from "react-icons/gi";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoMdAdd, IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { Button, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { GrTransaction } from "react-icons/gr";
import { FaDiagramSuccessor } from "react-icons/fa6";
import pdf from './../../assets/img/pdf.svg'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default function TransactionDetails() {
  const [data, setData] = useState([]);
  const [baseurl, setbaseurl] = useState();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [AllEmployee, setAllEmployee] = useState("");
  const [ActiveEmployee, setActiveEmployee] = useState("");
  const [PendingEmployee, setPendingEmployee] = useState("");
  const [FaildEmployee, setFaildEmployee] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const custumerdetails_id = localStorage.getItem("custumerdetails");
  const emp_id = localStorage.getItem("employee_id");
  const userid = localStorage.getItem("userid");
  const token = localStorage.getItem("bear_token");
  const broker_id = localStorage.getItem("broker_id");
  const [allData, setAllData] = useState([]);

  const employee_id = localStorage.getItem("employee_id");
  const [fromDate, setFromDate] = useState(null);  // Date object
  const [toDate, setToDate] = useState(null);      // Date object

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("emp_id", employee_id);
      const token = localStorage.getItem("bear_token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}small-admins-emp-payslip`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setbaseurl(response.data.trnximageUrl)
      setAllData(response.data.data);
      setData(response.data.data);
      const totalEmp = response.data.TotalEmployeeSalarySlip;
      const ActiveEmployee = response.data.MonthlySalary;
      const InactiveEmployee = response.data.TotalMonthlySalary;
      // const InactiveEmployee = response.data.TotalPendingTransaction;
      // const FaildEmployee = response.data.TotalFailedTransaction;
      
      setAllEmployee(totalEmp);
      setActiveEmployee(ActiveEmployee);
      setPendingEmployee(InactiveEmployee);
      // setFaildEmployee(FaildEmployee);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };
  const getFilteredTransactions = async (fromDate, toDate) => {
    if(data.length == 0){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "Sorry, no data found!",
        // timer: 2000
      });

      return
      }
    if (!fromDate || !toDate) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "Please choose a valid date!",
        // timer: 2000
      });
      setLoading(false);
      return;
    }
    const formattedFromDate = new Date(fromDate).toISOString().split("T")[0];
    const formattedToDate = new Date(toDate).toISOString().split("T")[0];

    try {
      const token = localStorage.getItem("bear_token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}small-admins-emp-filetrByDate-payslip?from_date=${formattedFromDate}&to_date=${formattedToDate}&smalladmins_id=${broker_id}&emp_id=${employee_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching filtered transactions:", error);
    }
  };

  const downloadFile = async (fromDate, toDate) => {
    if(data.length == 0){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "Sorry, no data found!",
        // timer: 2000
      });

      return
      }
    if (!fromDate || !toDate) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "Please choose a valid date!",
        // timer: 2000
      });
      setLoading(false);
      return;
    }
    const formattedFromDate = new Date(fromDate).toISOString().split("T")[0];
    const formattedToDate = new Date(toDate).toISOString().split("T")[0];
    try {
      const token = localStorage.getItem("bear_token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}small-admins-emp-filetrByDate-payslip-PDF?from_date=${formattedFromDate}&to_date=${formattedToDate}&smalladmins_id=${broker_id}&emp_id=${employee_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/pdf",
          },
          responseType: "blob",
        }
      );

      if (response.headers["content-type"] === "application/pdf") {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "Salary_Filter_Pay_Ship.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Response is not a PDF file");
      }
    } catch (error) {
      console.error("Error fetching PDF file:", error);
    }
  };

  const handleDownload = () => {
    downloadFile(fromDate, toDate);
  };

  const handleFilter = () => {
    getFilteredTransactions(fromDate, toDate);
  };

  const handleClick = (id) => {
    localStorage.setItem("Addplan_id", id);
    navigate("/Add-More-plan");
  };

  const handleClickAdd = (id) => {
    navigate("/Add-admins-customer");
  };

  const handleEditCustomerClick = (emp_id) => {
    localStorage.setItem("employee_id", emp_id);
    navigate("/EmployeeViewedit");
  };

  const handleViewCustomerClick = (emp_id) => {
    localStorage.setItem("employee_id", emp_id);
    navigate("/EmployeeView");
  };

  const filteredData = data ? data.filter(
    (item) =>
      item.trnx_id &&
      item.trnx_id.toLowerCase().includes(searchTerm.toLowerCase())
  ):[];
  
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleviveCustomerClick = (SalarySlipView_id) => {

    localStorage.setItem('SalarySlipView_id', SalarySlipView_id);
    navigate("/SalarySlipView");
  };
  const parseDateString = (dateString) => {
    return parse(dateString, "dd-MM-yyyy", new Date());
  };
  const handleSearch = () => {
    if (!fromDate || !toDate) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "Please choose a valid date!",
        // timer: 2000
      });
      
   
    }
    if (fromDate && toDate) {
      const filteredTransactions = allData.filter((item) => {
        const transactionDate = parseDateString(item.salary_slip_date);
        return (
          transactionDate >= fromDate &&
          transactionDate <= toDate
        );
      });
      setData(filteredTransactions);
    } else {
    
      setData(allData);
    }
  };
  const handleRefresh = () => {
  
    setFromDate('');
    setToDate('');
    setSearchTerm('');
    getProfile();
  };
  
  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h2 className="mb-0">Salary Pay slip Analytics</h2>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/EmployeeView" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="row g-3 mb-3">
        <div className="col-md-4 col-xxl-3">
          {/* <Link
            to=""
            className="linkWithoutUnderline active"
          > */}
            <div className="card h-md-100 ecommerce-card-min-width">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2 d-flex align-items-center">Total Pay Salary</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row align-items-center">
                  <div className="col pe-0">
                    <GiReceiveMoney className="fs-4 text-success" />
                    {/* <img src={company} alt="company" className="img-fluid"/> */}
                  </div>
                  <div className="col ps-0">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                      {AllEmployee}
                    </p>
                    {/* <span className="badge badge-subtle-success rounded-pill fs-11">
                +3.5%
              </span> */}
                  </div>
                  <div className="col-auto ps-0">
                    <div className="echart-bar-weekly-sales h-100" />
                  </div>
                </div>
              </div>
            </div>
          {/* </Link> */}
        </div>
        <div className="col-md-4 col-xxl-3">
          {/* <Link
            to="#"
            className="linkWithoutUnderline"
          > */}
            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2">Monthly Pay Salary </h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col pe-0">
                    <FaRegCalendarAlt className="fs-4 text-primary" />
                  </div>
                  <div className="col ps-0">
                    <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                      <p className="font-sans-serif lh-1 mb-1 fs-5 text-primary">
                        {ActiveEmployee|| 0}
                        
                      </p>
                    </div>
                    {/* <span className="badge rounded-pill fs-11 bg-200 text-primary">
                <span className="fas fa-caret-up me-1" />
                13.6%
              </span> */}
                  </div>
                  <div className="col-auto ps-0 mt-n4">
                    <div
                      className="echart-default-total-order"
                      data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                      data-echart-responsive="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          {/* </Link> */}
        </div>




        <div className="col-md-4 col-xxl-3">
          {/* <Link
            to="#"
            className="linkWithoutUnderline"
          > */}
            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2">This Month Pay Salary </h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col pe-0">
                    <FaRegCalendarAlt className="fs-4 text-primary" />
                  </div>
                  <div className="col ps-0">
                    <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                      <p className="font-sans-serif lh-1 mb-1 fs-5 text-primary">
                        {PendingEmployee || 0}
                       
                        
                      </p>
                    </div>
                    {/* <span className="badge rounded-pill fs-11 bg-200 text-primary">
                <span className="fas fa-caret-up me-1" />
                13.6%
              </span> */}
                  </div>
                  <div className="col-auto ps-0 mt-n4">
                    <div
                      className="echart-default-total-order"
                      data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                      data-echart-responsive="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          {/* </Link> */}
        </div>
     
      </div>

      <div className="card mb-3">
        <div className="bg-holder d-none d-lg-block bg-card" />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0"> Total Salary Pay slip List</h3>
            </div>
            <div className="col-lg-4 d-flex justify-content-end text-end">
          
           <Link to="/Add-Employee-SalarySlip"> 
           
                <button className="btn  text-light bg-success">
             
                  <IoMdAdd className="me-1 fs--2" /> Add Transaction
                </button>
           
           </Link>
              
            </div>
          </div>
        </div>
      </div>

     
      <div className="card mb-3">
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-3 mb-3">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by Transaction Id"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-9 text-end">
              <Row>
              <div className="col-md-4 form_details">
              <div className="form-group local-forms">
                  <label className="form-label">From Date</label>
               
                <DatePicker
                   selected={fromDate} 
                    className="form-control"
                   onChange={(date) => setFromDate(date)} 
                   dateFormat="dd/MM/yyyy"
                   placeholderText="DD-MM-YYYY"
                 />
                </div>
                </div>
                <div className="col-md-4 form_details">
                 
                 
                 <div className="form-group local-forms">
           <label className="form-label">To Date</label>
        
         <DatePicker
            selected={toDate} 
             className="form-control"
            onChange={(date) => setToDate(date)} 
            dateFormat="dd/MM/yyyy"
            placeholderText="DD-MM-YYYY"
          />
         </div>
         </div>
                <div className="col-md-4 d-flex gap-1 justify-content-evenly  align-item-space-between">
                  {/* <div className="mb-3"> */}
                  <button className="btn btn-primary " onClick={handleSearch}>
                    Search
                  </button>
                  <button className="btn btn-dark " onClick={handleRefresh}>
                  Refresh
                  </button>
                  <button className="btn btn-dark " onClick={handleDownload}>
                  <FaCloudDownloadAlt className="fs-4 text-light" />
                  </button>
                  {/* <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {" "}
                        Download Transaction PDF
                      </Tooltip>
                    }
                  >
                    <span className="d-inline-block">
                      <button
                        className="buttonfordownload"
                        onClick={handleDownload}
                      >
                    
                        <FaCloudDownloadAlt className="fs-4 text-dark" />
                      </button>
                    </span>
                  </OverlayTrigger> */}
                  {/* <MdArrowOutward className="" /> */}
                </div>
              </Row>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
  <div className="card-body">
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead
          style={{
            backgroundColor: "#f2f2f2",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          <tr>
          
          
            <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>
             S. No.
            </th>
            <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>
            Transaction ID
            </th>
            <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>
              Basic Salary
            </th>
            {/* <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>
              HRA
            </th>
            <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>
              Other Allowances
            </th> */}
            <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>
              Net Payable Salary
            </th>
            <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>
              Salary Slip Date
            </th>
            <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>
            Action
            </th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((item, index) => (
            <tr key={item.emp_id}>
              <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>
                {startIndex + index + 1}
              </td>
            
              <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>
                {item.trnx_id}
              </td>
              <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>
              <BsCurrencyRupee/>{" "}{item.basic_salary}
              </td>
              {/* <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>
                {item.hra}
              </td>
              <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>
                {item.other_allowances}
              </td> */}
              <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>
              <BsCurrencyRupee/>{" "}{item.net_payable_salary}
              </td>
              <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>
                {item.salary_slip_date}
              </td>
              <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>
              <td>
          <div className="d-flex gap-1 action_button">
            <OverlayTrigger
              key="View"
              placement="top"
              overlay={<Tooltip id={`View`}>View</Tooltip>}
            >
              <button
                onClick={() => handleviveCustomerClick(item.id)}
                className="bg-success"
              >
                <FaEye className="color_link" />
              </button>
            </OverlayTrigger>
          </div>
        </td>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <div className="d-flex justify-content-between mt-3">
      <div>
        Page {currentPage} of {totalPages}
      </div>
      <div className="d-flex gap-2">
        <button
          className="border-0"
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <IoIosArrowBack />
        </button>
        <div className="pagecurrent">{currentPage}</div>
        <button
          className="border-0"
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <IoIosArrowForward />
        </button>
      </div>
    </div>
  </div>
</div>



    </>
  );
}
