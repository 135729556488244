import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

import Swal from "sweetalert2";
export default function AddEmployee() {
  const broker_id = localStorage.getItem("broker_id");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [newImagepan, setNewImagepan] = useState(null);
  const [newImageadhar, setNewImageadar] = useState(null);
  const [depatment, setDepatment] = useState("");
  const [depatmentmanager, setDepatmentmanager] = useState("");
  const [fileimage, setFileimage] = useState("");
  const [adharimg, setAdharImg] = useState("");

  const [newImageadharback, setNewImageadarBack] = useState(null);
  const [adharbackimg, setAdharBackImg] = useState("");

  const [panimg, setPanImg] = useState("");
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    personal_email: "",
    dept_id: "",
    joining_date: new Date().toISOString().split("T")[0],
    total_experience: "",
    confirmation_date: new Date().toISOString().split("T")[0],
    pre_work_exp: "",
    pre_work_exp: "",
    mobile: "",
    emp_salary: "",
    designation: "",
    emp_lavel: "",
    alternate_mobile: "",
    current_address: "",
    permanant_address: "",
    dob: new Date().toISOString().split("T")[0],
    gender: "",
    marital_status: "",
    pan_number: "",
    bank_ac_no: "",
    acc_holder_name: "",
    bank_branch: "",
    upi_id: "",
    pf_no: "",
    esi_no: "",
    aadhar_no: "",
    password: "",
    reporting_manager_id:"",
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileimage(URL.createObjectURL(file));
    setNewImage(file);
  };
  const handleAdharImageChange = (e) => {
    const file = e.target.files[0];
    setAdharImg(URL.createObjectURL(file));
    setNewImageadar(file);
  };
  const handleAdharBackImageChange = (e) => {
    const file = e.target.files[0];
    setAdharBackImg(URL.createObjectURL(file));
    setNewImageadarBack(file);
  };
  const handlePanImageChange = (e) => {
    const file = e.target.files[0];
    setPanImg(URL.createObjectURL(file));
    setNewImagepan(file);
  };

  useEffect(() => {
    fetchData2();
  }, []);
  useEffect(() => {
    if (formData.dept_id) {
      fetchData3();
    }
  }, [formData.dept_id]);
  const fetchData2 = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const updateformData = new FormData();

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}super-admins-employee-depatment-details-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: updateformData,
      };

      const response = await axios(config);
      console.warn(response.data.data);
      const data = response.data.data;
      setDepatment(data);
    } catch (error) {
      console.error("Error fetching user broker:", error);
    }
  };
  const fetchData3 = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const updateformData = new FormData();
      updateformData.append("dept_id", formData.dept_id);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}super-admins-employee-manager-details-lists
`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: updateformData,
      };

      const response = await axios(config);
      console.warn(response.data);
      const data = response.data.data;
      setDepatmentmanager(data);
    } catch (error) {
      console.error("Error fetching user broker:", error);
    }
  };
  const handleInputmanger = (e) => {
    const { name, value } = e.target;

    setDepatmentmanager(value);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "mobile" && (value.length > 10 || isNaN(value))) {
      return;
    }

    if (name === "alternate_mobile" && (value.length > 10 || isNaN(value))) {
      return;
    }
    // if (name === "dept_id" || name === "reporting_manager_id") {
    //   if (!/^\d*$/.test(value) || value.length > 10) {
    //     return;
    //   }
    // }
    if (name === "total_experience") {
      if (!/^\d*$/.test(value) || value.length > 10) {
        return;
      }
    }
    if (name === "pre_work_exp") {
      if (!/^\d*$/.test(value) || value.length > 10) {
        return;
      }
    }
    if (
      name === "pf_no" &&
      (value.length > 20 || !/^[A-Z0-9]+$/i.test(value))
    ) {
      return;
    }

    if (
      name === "esi_no" &&
      (value.length > 20 || !/^[A-Z0-9]+$/i.test(value))
    ) {
      return;
    }

    let formattedValue = value;

    if (name === "pan_number") {
      formattedValue = value
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, "")
        .slice(0, 10);
    } else if (name === "ifsc") {
      formattedValue = value
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, "")
        .slice(0, 11);
    } else if (name === "bank_ac_no") {
      formattedValue = value.replace(/[^0-9]/g, "").slice(0, 18);
    } else if (name === "aadhar_no") {
      if (!/^\d*$/.test(value) || value.length > 12) {
        return;
      }
    }

    if (name === "name") {
      formattedValue = value.replace(/[^A-Za-z\s]/g, "").slice(0, 40);
    }
    if (name === "acc_holder_name") {
      formattedValue = value
        .replace(/[^A-Za-z\s]/g, "") // Allow spaces
        .slice(0, 40);
    }
    if (name === "bank_branch") {
      formattedValue = value
        .replace(/[^A-Za-z\s]/g, "") // Allow spaces
        .slice(0, 40);
    }

    setFormData({ ...formData, [name]: formattedValue });

    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requiredFields = [
      { field: "name", name: "Name" },
      { field: "email", name: "Email" },
      // { field: "personal_email", name: "Personal Email" },
      // { field: "profile", name: "Profile Picture" },
      // { field: "reporting_manager_id", name: "Reporting Manager ID" },
      // { field: "joining_date", name: "Joining Date" },
      // { field: "total_experience", name: "Total Experience" },
      // { field: "confirmation_date", name: "Confirmation Date" },
      // { field: "pre_work_exp", name: "Pre Work Experience" },
      { field: "mobile", name: "Mobile" },
      // { field: "alternate_mobile", name: "Alternate Mobile" },
      { field: "current_address", name: "Current Address" },
      { field: "permanant_address", name: "Permanent Address" },
      { field: "dob", name: "Date of Birth" },
      { field: "gender", name: "Gender" },
      { field: "marital_status", name: "Marital Status" },
      { field: "pan_number", name: "PAN Number" },
      { field: "bank_ac_no", name: "Bank Account Number" },
      { field: "acc_holder_name", name: "Account Holder Name" },
      { field: "bank_branch", name: "Bank Branch" },
      // { field: "upi_id", name: "Upi Id" },
      { field: "pf_no", name: "PF Number" },
      { field: "esi_no", name: "ESI Number" },
      { field: "aadhar_no", name: "Aadhar Number" },
      { field: "password", name: "Password" },
      { field: "emp_salary", name: "Emp Salary" },
      { field: "emp_lavel", name: "Emp Lavel" },
      { field: "designation", name: "Designation" },
    ];

    let validationErrors = {};
    let hasError = false;

    for (const { field, name } of requiredFields) {
      if (!formData[field]) {
        validationErrors[field] = `Please fill in the ${name}.`;
        hasError = true;
      }
    }

    setErrors(validationErrors);

    if (hasError) {
      setLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem("bear_token");
      const updatedFormData = new FormData();
      updatedFormData.append("smalladmins_id", broker_id);
      // updatedFormData.append("reporting_manager_id", depatmentmanager);

      updatedFormData.append("status", "1");
      Object.keys(formData).forEach((key) => {
        updatedFormData.append(key, formData[key]);
      });



      if (newImage) {
        updatedFormData.append("profile", newImage);
      }
      if (newImagepan) {
        updatedFormData.append("pan_image", newImagepan);
      }
   
      if (newImageadhar) {
        updatedFormData.append("uan_image", newImageadhar);
      }
      if (newImageadharback) {
        updatedFormData.append("back_uan_image", newImageadharback);
      }

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}add-small-admins-emp`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: updatedFormData,
      };

      const response = await axios(config);
      const data = response.data.status;
      console.warn(response);
      if (data === 1) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
          color: "white",
          background: "transparent",
        });

        setTimeout(() => {
          navigate("/Employeemanagement");
        }, 2000);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data.message,
          color: "white",
          background: "transparent",
        });
      }
    } catch (error) {
      console.error("Error adding employee:", error);
      Swal.fire({
        icon: "error",
        title: "Email Already Exists",
        text: "The email ID you entered is already in use. Please try another one.",
        color: "white",
        background: "transparent",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <Row>
            <Col lg={8}>
              <h3 className="mb-0">Add Employee</h3>
            </Col>
            <div className="col-lg-4 text-end">
              <Link to="/Employeemanagement" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </Row>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <h4 className="mb-3">Personal Information</h4>
          <form onSubmit={handleSubmit}>
            <Row>
              <div className="col-md-6 form_details">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="name">
                    Name <span className="login-danger">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.name ? "input-error" : ""
                    }`}
                    id="name"
                    name="name"
                    type="text"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6 form_details">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="email">
                    Email <span className="login-danger">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.email ? "input-error" : ""
                    }`}
                    id="email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="personal_email">
                    Personal Email <span className="login-danger"></span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.personal_email ? "input-error" : ""
                    }`}
                    id="personal_email"
                    name="personal_email"
                    type="email"
                    value={formData.personal_email}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="mobile">
                    Mobile <span className="login-danger">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.mobile ? "input-error" : ""
                    }`}
                    id="mobile"
                    name="mobile"
                    type="text"
                    value={formData.mobile}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="alternate_mobile">
                    Alternate Mobile <span className="login-danger"></span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.alternate_mobile ? "input-error" : ""
                    }`}
                    id="alternate_mobile"
                    name="alternate_mobile"
                    type="text"
                    value={formData.alternate_mobile}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              {/* <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="emergency_mobile">
                    Emergency Mobile <span className="login-danger">*</span>
                  </label>
                  <input
                    className={`form-control ${errors.emergency_mobile ? 'input-error' : ''}`}
                    id="emergency_mobile"
                    name="emergency_mobile"
                    type="text"
                    value={formData.emergency_mobile}
                    onChange={handleInputChange}
                  />
                </div>
              </div> */}
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="dob">
                    Date of Birth <span className="login-danger">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.dob ? "input-error" : ""
                    }`}
                    id="dob"
                    name="dob"
                    type="date"
                    value={formData.dob || new Date().toISOString().split("T")[0]} 
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="password">
                    Password <span className="login-danger">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.password ? "input-error" : ""
                    }`}
                    id="password"
                    name="password"
                    type="text"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="gender">
                    Gender <span className="login-danger">*</span>
                  </label>
                  <select
                    className={`form-select ${
                      errors.gender ? "input-error" : ""
                    }`}
                    // className="form-select"
                    id="gender"
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}
                    // style={{
                    //   color: formData.gender === " " ? "#b0bcc7" : "#000000", // Secondary color for placeholder, dark color for other options
                    // }}
                  >
                    <option value=" " >Select</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="marital_status">
                    Marital Status <span className="login-danger">*</span>
                  </label>
                  <select
                    className={`form-select ${
                      errors.gender ? "input-error" : ""
                    }`}
                    // className="form-select"
                    id="marital_status"
                    name="marital_status"
                    value={formData.marital_status}
                    onChange={handleInputChange}
                    // style={{
                    //   color: formData.marital_status === " " ? "#b0bcc7" : "#000000", // Secondary color for placeholder, dark color for other options
                    // }}
                  > 
                    <option value=" " >Select</option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                  </select>
                </div>
              </div>

              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="profile">
                    Profile Picture <span className="login-danger"></span>
                  </label>
                  <input
                    // className="form-control"
                    className={`form-control ${
                      errors.profile ? "input-error" : ""
                    }`}
                    id="profile"
                    name="profile"
                    type="file"
                    value={formData.profile}
                    onChange={handleImageChange}
                  />
                  {fileimage && (
                    <div className="mt-3">
                      <img
                        src={fileimage}
                        alt="Selected profile"
                        style={{ height: "100px" }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <hr className="my-4 mx-n4" />
              <h5>Work Information</h5>

              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="dept_id">
                    Department Name <span className="login-danger"></span>
                  </label>
                  <select
                    className={`form-select ${
                      errors.dept_id ? "input-error" : ""
                    }`}
                    id="dept_id"
                    name="dept_id"
                    value={formData.dept_id}
                    onChange={handleInputChange}
                    style={{
                      color: formData.dept_id === " " ? "#b0bcc7" : "#000000", // Secondary color for placeholder, dark color for other options
                    }}
                  >
                    <option value=" " disabled>Select</option>
                    {depatment &&
                      depatment.map((dept) => (
                        <option key={dept.id} value={dept.id}>
                          {dept.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="reporting_manager_id">
                    Reporting Manager <span className="login-danger"></span>
                  </label>
                  <select
                    className={`form-select ${
                      errors.reporting_manager_id ? "input-error" : ""
                    }`}
                    id="reporting_manager_id"
                    name="reporting_manager_id"
                    value={formData.reporting_manager_id}
                    onChange={handleInputChange}
                    style={{
                      color: formData.reporting_manager_id === " " ? "#b0bcc7" : "#000000", // Secondary color for placeholder, dark color for other options
                    }}
                  >
                    <option value=" " disabled>Select</option>
                    {depatmentmanager &&
                      depatmentmanager.map((dept) => (
                        <option key={dept.id} value={dept.id}>
                          {dept.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="total_experience">
                    Total Experience <span className="login-danger"></span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.total_experience ? "input-error" : ""
                    }`}
                    id="total_experience"
                    name="total_experience"
                    type="text"
                    value={formData.total_experience}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="pre_work_exp">
                    Pre Work Experience <span className="login-danger"></span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.pre_work_exp ? "input-error" : ""
                    }`}
                    id="pre_work_exp"
                    name="pre_work_exp"
                    type="text"
                    value={formData.pre_work_exp}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="joining_date">
                    Joining Date <span className="login-danger"></span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.joining_date ? "input-error" : ""
                    }`}
                    id="joining_date"
                    name="joining_date"
                    type="date"
                    value={formData.joining_date} 
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="confirmation_date">
                    Confirmation Date <span className="login-danger"></span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.confirmation_date ? "input-error" : ""
                    }`}
                    id="confirmation_date"
                    name="confirmation_date"
                    type="date"
                    value={formData.confirmation_date} 
                    onChange={handleInputChange}
                  />
                </div>
              </div>

           

              <hr className="my-4 mx-n4" />

              <h5>Salary Details</h5>

              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="emp_lavel">
                    Select Label <span className="login-danger">*</span>
                  </label>
                  <select
                    className={`form-select ${
                      errors.emp_lavel ? "input-error" : ""
                    }`}
                    id="emp_lavel"
                    name="emp_lavel"
                    value={formData.emp_lavel}
                    onChange={handleInputChange}
                    style={{
                      color: formData.emp_lavel === "" ? "#b0bcc7" : "#000000", // Secondary color for placeholder, dark color for other options
                    }}
                  >
                    <option value="" disabled>
                      Select
                    </option>
   
                    <option value="band_1">Band-1</option>
                    <option value="band_2">Band-2</option>
                    <option value="band_3">Band-3</option>
                    <option value="band_4">Band-4</option>
                    {/* ))} */}
                  </select>
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="designation">
                    Select Designation <span className="login-danger">*</span>
                  </label>
                  <select
                    className={`form-select ${
                      errors.designation ? "input-error" : ""
                    }`}
                    id="designation"
                    name="designation"
                    value={formData.designation}
                    onChange={handleInputChange}
                    style={{
                      color:
                        formData.designation === "" ? "#b0bcc7" : "#000000", // Secondary color for placeholder, dark color for other options
                    }}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="intern">Intern</option>
                    <option value="junior">Junior</option>
                    <option value="senior">Senior</option>
                    <option value="team_leader">Team Leader</option>
                    <option value="project_manager">Project Manager</option>
                  </select>
                </div>
              </div>

              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="emp_salary">
                    Salary <span className="login-danger">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.emp_salary ? "input-error" : ""
                    }`}
                    id="emp_salary"
                    name="emp_salary"
                    type="text"
                    pattern="[0-9]*"
                    value={formData.emp_salary}
                    onChange={handleInputChange}
                    inputMode="numeric" // Show numeric keyboard on mobile devices
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                    }}
                  />
                </div>
              </div>
              <hr className="my-4 mx-n4" />

              <h5>Address Information</h5>

              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="current_address">
                    Current Address <span className="login-danger">*</span>
                  </label>
                  <textarea
                    className={`form-control ${
                      errors.current_address ? "input-error" : ""
                    }`}
                    id="current_address"
                    name="current_address"
                    type="text"
                    value={formData.current_address}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="permanant_address">
                    Permanent Address <span className="login-danger">*</span>
                  </label>
                  <textarea
                    className={`form-control ${
                      errors.permanant_address ? "input-error" : ""
                    }`}
                    id="permanant_address"
                    name="permanant_address"
                    type="text"
                    value={formData.permanant_address}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <hr className="my-4 mx-n4" />
              <h5>Bank Information</h5>
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="bank_ac_no">
                    Bank Account Number <span className="login-danger">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.bank_ac_no ? "input-error" : ""
                    }`}
                    id="bank_ac_no"
                    name="bank_ac_no"
                    type="text"
                    value={formData.bank_ac_no}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="acc_holder_name">
                    Account Holder Name <span className="login-danger">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.acc_holder_name ? "input-error" : ""
                    }`}
                    id="acc_holder_name"
                    name="acc_holder_name"
                    type="text"
                    value={formData.acc_holder_name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="bank_branch">
                    Bank Branch <span className="login-danger">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.bank_branch ? "input-error" : ""
                    }`}
                    id="bank_branch"
                    name="bank_branch"
                    type="text"
                    value={formData.bank_branch}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <hr className="my-4 mx-n4" />
              <h5>Identification Information</h5>

              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="pan_number">
                    PAN Number <span className="login-danger">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.pan_number ? "input-error" : ""
                    }`}
                    id="pan_number"
                    name="pan_number"
                    type="text"
                    value={formData.pan_number}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="pan_image">
                    PAN Card Image <span className="login-danger"></span>
                  </label>
                  <input
                    // className="form-control"
                    className={`form-control ${
                      errors.pan_image ? "input-error" : ""
                    }`}
                    id="pan_image"
                    name="pan_image"
                    type="file"
                    value={formData.pan_image}
                    onChange={handlePanImageChange}
                  />
                  {panimg && (
                    <div className="mt-3">
                      <img
                        src={panimg}
                        alt="Selected profile"
                        style={{ height: "100px" }}
                      />
                    </div>
                  )}
                </div>
              </div>
         

            

          
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="aadhar_no">
                  Aadhaar Number <span className="login-danger">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.aadhar_no ? "input-error" : ""
                    }`}
                    id="aadhar_no"
                    name="aadhar_no"
                    type="text"
                    value={formData.aadhar_no.slice(0, 12)}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="uan_image">
                  Aadhaar Front Image <span className="login-danger"></span>
                  </label>
                  <input
                    // className="form-control"
                    className={`form-control ${
                      errors.uan_image ? "input-error" : ""
                    }`}
                    id="uan_image"
                    name="uan_image"
                    type="file"
                    value={formData.uan_image}
                    onChange={handleAdharImageChange}
                  />
                  {adharimg && (
                    <div className="mt-3">
                      <img
                        src={adharimg}
                        alt="Selected profile"
                        style={{ height: "100px" }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                 
              
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="back_uan_image">
                  Aadhaar Back Image <span className="login-danger"></span>
                  </label>
                  <input
                    // className="form-control"
                    className={`form-control ${
                      errors.back_uan_image ? "input-error" : ""
                    }`}
                    id="back_uan_image"
                    name="back_uan_image"
                    type="file"
                    value={formData.back_uan_image}
                    onChange={handleAdharBackImageChange}
                  />
                  {adharbackimg && (
                    <div className="mt-3">
                      <img
                        src={adharbackimg}
                        alt="Selected Aadhaar"
                        style={{ height: "100px" }}
                      />
                    </div>
                  )}
                </div>
              </div>
        
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="pf_no">
                    PF Number <span className="login-danger">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.pf_no ? "input-error" : ""
                    }`}
                    id="pf_no"
                    name="pf_no"
                    type="text"
                    value={formData.pf_no}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="upi_id">
                    Upi Id <span className="login-danger"></span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.upi_id ? "input-error" : ""
                    }`}
                    id="upi_id"
                    name="upi_id"
                    type="text"
                    value={formData.upi_id}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="esi_no">
                    ESI Number <span className="login-danger">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.esi_no ? "input-error" : ""
                    }`}
                    id="esi_no"
                    name="esi_no"
                    type="text"
                    value={formData.esi_no}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </Row>
            <div className="pt-4 w-100 d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
              >
                Submit
                {loading && (
                  <Spinner
                    animation="border"
                    style={{
                      width: "15px",
                      height: "15px",
                      marginLeft: "10px",
                    }}
                  />
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
